import {Controller} from "@hotwired/stimulus"
import {get} from "@rails/request.js";

// Connects to data-controller="score-select"
export default class extends Controller {
  apply(event) {
    const selectedValue = event.target.value
    const id = event.target.dataset.id
    get(`/ixly/scores/${id}?norm_group=${selectedValue}`, {
      responseKind: "turbo-stream"
    })
  }
}

import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="dropdown"
export default class extends Controller {
  static targets = ['dropdown'];

  connect() {
    document.addEventListener('click', this.handleClickOutside.bind(this));
  }

  disconnect() {
    document.removeEventListener('click', this.handleClickOutside.bind(this));
  }

  open() {
    this.dropdownTarget.classList.remove('hidden');
  }

  toggle() {
    if (this.dropdownTarget.classList.contains('hidden')) {
      this.dropdownTarget.classList.remove('hidden');
    } else {
      this.dropdownTarget.classList.add('hidden');
    }
  }

  handleClickOutside(event) {
    if (!this.element.contains(event.target)) {
      this.dropdownTarget.classList.add('hidden');
    }
  }
}

import { Controller } from "@hotwired/stimulus"
import { useDebounce } from "stimulus-use"

// Connects to data-controller="filterbar"
export default class extends Controller {
  static targets = ['query', 'form', 'reset']
  static debounces = ['submit']

  connect() {
    useDebounce(this, { wait: 500 })
  }

  submit() {
    this.formTarget.requestSubmit()
  }
}

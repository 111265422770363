import {Controller} from "@hotwired/stimulus"
import { useDebounce } from "stimulus-use"
import { post } from "@rails/request.js"

// This stimulus controller will convert the rendered aside into a Flowbite drawer. It has a toggle method to open/close the drawer.
// Connects to data-controller="sidebar"
export default class extends Controller {
  static values = { path: String }
  static debounces = ['submitWithDebounce']
  static outlets = ['filterbar']

  connect() {
    useDebounce(this, { wait: 500 })

    this.drawer = new Drawer(this.element, {
      placement: 'right',
      backdrop: false,
      bodyScrolling: true
    })
  }

  disconnect() {
    // If the drawer is open when navigating to a different page, problems can occur with opening the filters in the new page. To prevent this, always close the drawer on disconnect.
    this.hide()
  }

  toggle() {
    this.drawer.toggle()
  }

  hide() {
    this.drawer.hide()
  }

  submit() {
    this.filterbarOutlet.submit()
  }

  submitWithDebounce() {
    this.filterbarOutlet.submit()
  }

  async reset() {
    try {
      await post(this.pathValue, { responseKind: "turbo-stream" });
      this.submit()
    } catch (error) {
      console.log('Error:', error);
    }
  }
}

import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="subscription-form"
export default class extends Controller {
  static targets = ["subscriptionTypeInput", "annualFeeInput"]

  toggleAnnualField() {
    const selectedType = this.subscriptionTypeInputTarget.options[this.subscriptionTypeInputTarget.selectedIndex].getAttribute('data-type')
    const annualFeeElement = document.getElementById('annual_fee_element')
    if (selectedType === 'AnnualSubscription') {
      annualFeeElement.classList.remove('hidden')
      this.annualFeeInputTarget.setAttribute('required', 'required');
    } else {
      annualFeeElement.classList.add('hidden')
      this.annualFeeInputTarget.removeAttribute('required');
      this.annualFeeInputTarget.value = ''
    }
  }
}

import {Controller} from "@hotwired/stimulus"

// Connects to data-controller="favorite"
export default class extends Controller {
  static targets = ['nonFavoriteIcon', 'favoriteIcon']

  toggleIcon() {
    this.nonFavoriteIconTarget.classList.toggle('hidden')
    this.favoriteIconTarget.classList.toggle('hidden')
  }
}

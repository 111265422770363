import { Controller } from "@hotwired/stimulus"
import { patch } from "@rails/request.js"

// Connects to data-controller="left-menu"
export default class extends Controller {
  static targets = ['menuItem', 'subMenuItem', 'topMenuItem', 'toggleIcon']
  static classes = [ 'menuCollapsed', 'menuExpanded', 'itemCollapsed', 'itemExpanded', 'topItemCollapsed' ]

  toggle(event) {
    this.toggleIconsAndNavbar()
    this.toggleMenuItems()
    this.toggleTopMenuItems(event)
    this.toggleSubMenuItems()
    if (event.type == 'click') {
      this.saveToggle()
    }
  }

  toggleIconsAndNavbar() {
    this.toggleIconTargets.forEach((icon) => { icon.classList.toggle('hidden') })

    this.element.classList.toggle(this.menuCollapsedClass);
    this.element.classList.toggle(this.menuExpandedClass);
  }

  toggleMenuItems() {
    this.menuItemTargets.forEach((menuItem) => {
      // set the label to be screen reader only, except on mobile size
      const label = menuItem.querySelector('span');
      label.classList.toggle('sm:sr-only')

      // hide tooltip when left menu is expanded
      const tooltip = menuItem.querySelector('.tooltip');
      tooltip.classList.toggle('hidden')

      // adjust the width of the left menu <li> item
      menuItem.classList.toggle(this.itemCollapsedClass);
      menuItem.classList.toggle(this.itemExpandedClass);
    })
  }

  toggleTopMenuItems(event) {
    this.topMenuItemTargets.forEach((menuItem) => {
      // set the label to be screen reader only, except on mobile size, when left menu is collapsed
      const label = menuItem.querySelector('span');
      label.classList.toggle('sm:sr-only')

      // hide tooltip when left menu is expanded
      const tooltip = menuItem.querySelector('.tooltip');
      tooltip.classList.toggle('hidden')

      // adjust the width of the left menu <li> item
      menuItem.classList.toggle(this.itemExpandedClass);
      menuItem.classList.toggle(this.topItemCollapsedClass);

      // when the menu is expanded, place the caret on the rightermost edge of the list item
      const caret = menuItem.querySelector('.flex-grow')
      caret.classList.toggle('flex-row-reverse')

      // When menu is collapsed set action to open menu on hover for list items with a submenu, but remove this hover effect when the menu is expanded
      if (event.type == 'click') {
        if (menuItem.getAttribute('data-action') == '') {
          menuItem.setAttribute('data-action', 'mouseover->left-menu#toggle mouseout->left-menu#toggle')
        } else {
          menuItem.setAttribute('data-action', '')
        }
      }
    })
  }

  toggleSubMenuItems() {
    this.subMenuItemTargets.forEach((menuItem) => {
      menuItem.classList.toggle(this.itemExpandedClass);
      menuItem.classList.toggle('sm:hidden');
    })
  }

  saveToggle() {
    // if contains it means collapsed
    const value = this.element.classList.contains(this.menuCollapsedClass)

    patch(`/left_navigation_menu/${value}`)
  }

  openNav() {
    const navigation = document.getElementById('left-navigation-menu');
    navigation.classList.replace('-translate-x-full', 'translate-x-0')
  }

  closeNav() {
    this.element.classList.replace('translate-x-0', '-translate-x-full')
  }
}

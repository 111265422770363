import { Controller } from "@hotwired/stimulus"
import { patch, destroy } from "@rails/request.js";

// Connects to data-controller="candidate-selection"
export default class extends Controller {
  static outlets = ["overview-list"]
  static targets = ["informationBanner", "selectAllButton"]
  static values = { preselectedUuids: Array, preselectedFilteredUuids: Array }

  connect() {
    this.selectAllHandler = this.selectAll.bind(this)
    document.addEventListener('select-all', this.selectAllHandler);

    this.toggleUIElements();
  }

  disconnect() {
    document.removeEventListener('select-all', this.selectAllHandler);
  }

  selectAll(event) {
    const { candidate_uuids, checked, source_context, matching_assignment_uuid } = event.detail;
    this.storeSelection(candidate_uuids, checked, source_context, matching_assignment_uuid)
    this.toggleUIElements();
  }

  select(event) {
    const { value, checked } = event.target;
    this.storeSelection([value], checked, event.target.dataset.sourceContext)
    this.toggleUIElements();
  }

  reset() {
    this.preselectedUuidsValue = []
    this.preselectedFilteredUuidsValue = []

    if(this.hasOverviewListOutlet) {
      this.overviewListOutlet.selectedRowOutlets.forEach(row => {
        row.checkboxTarget.checked = false
        row.toggle()
      })
    }
    this.toggleUIElements();
  }

  storeSelection(uuids, checked, source_context, matching_assignment_uuid = null) {
    this.updateUuidsValue(uuids, checked)

    const formData = new FormData();
    uuids.forEach(uuid => formData.append('candidate_uuids[]', uuid));
    formData.append('source_context', source_context);

    if(source_context == 'matching_candidate_selection'){
      const matchingAssignmentUuid = event.target.dataset?.matchingAssignmentUuid || matching_assignment_uuid;
      if(matchingAssignmentUuid) {
        formData.append('matching_assignment_uuid', matchingAssignmentUuid);
      }
    }

    if(checked){
      patch('/assessments/candidate_selection', { body: formData })
    }else {
      destroy('/assessments/candidate_selection', { body: formData })
    }
  }

  toggleUIElements() {
    this.toggleActionMenu();
    this.updateInformationBanner();
    if(this.hasOverviewListOutlet) this.overviewListOutlet.toggleSelectAllCheckbox()
  }

  toggleActionMenu(){
    const count = this.preselectedUuidsValue.length
    const lastCheckedElementValue = this.preselectedUuidsValue.pop()

    if(this.hasOverviewListOutlet) this.overviewListOutlet.showActionMenu(count, lastCheckedElementValue)
  }

  updateUuidsValue(uuids, checked) {
    const updatedArray = new Set(this.preselectedUuidsValue);
    const updatedFilteredArray = new Set(this.preselectedFilteredUuidsValue);

    const updateFunction = checked ? 'add' : 'delete';
    uuids.forEach(item => {
      updatedArray[updateFunction](item);
      updatedFilteredArray[updateFunction](item);
    });

    this.preselectedUuidsValue = [...updatedArray]
    this.preselectedFilteredUuidsValue = [...updatedFilteredArray]
  }

  updateInformationBanner() {
    if(this.hasInformationBannerTarget == false) return

    const filtered_count_element = this.informationBannerTarget.querySelector("span#filtered_candidates_count");
    const candidates_count_element = this.informationBannerTarget.querySelector("span#candidates_count");

    filtered_count_element.innerText = this.preselectedFilteredUuidsValue.length
    candidates_count_element.innerText = this.preselectedUuidsValue.length

    this.toggleBanner()
    this.toggleSelectAllButton()
  }

  toggleBanner() {
    const table_row = document.getElementById("candidate-list-table-row");
    const isBannerVisible = this.preselectedUuidsValue.length > 0;

    if (isBannerVisible) {
      this.informationBannerTarget.classList.remove("hidden")
      table_row.classList.replace('top-20', 'top-36')
    } else {
      this.informationBannerTarget.classList.add("hidden")
      table_row.classList.replace('top-36', 'top-20')
    }
  }

  toggleSelectAllButton() {
    const totalFilteredCountValue = document.getElementById("total_filtered_count").innerText.replace(/[^0-9]/g, '')
    const isAllSelected = this.preselectedFilteredUuidsValue.length == +totalFilteredCountValue;

    this.selectAllButtonTarget.disabled = isAllSelected;
  }
}

import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="custom-tooltip"
// This is a custom trigger for the flowbite tooltip
// if we would have named it the tooltip controller it would interfer with the flowbite tooltip itself
export default class extends Controller {
  static targets = [ 'trigger', 'content' ]
  static values = { placement: String }

  connect() {
    this.initializeTooltip()
    document.addEventListener('turbo:frame-load', this.recreateTooltip.bind(this))
  }

  disconnect() {
    document.removeEventListener('turbo:frame-load', this.recreateTooltip.bind(this))
  }

  recreateTooltip(event) {
    const frameId = event.target.id

    if (frameId.includes('_tooltip_content')) {
      this.initializeTooltip()
    }
  }

  initializeTooltip() {
    if(this.hasContentTarget) {
      if(this.hasPlacementValue) {
        var options = {  placement: this.placementValue}
      }
      this.tooltip = new Tooltip(this.contentTarget, this.triggerTarget, options)
    }
  }
}

import { Controller } from "@hotwired/stimulus"
import { get } from "@rails/request.js"

// Connects to data-controller="form-multi-select"
export default class extends Controller {
  static targets = ['generalSection']
  static values = { 'general-path': String }

  getGeneralPartial () {
    const section = this.generalSectionTarget;
    const path = this.generalPathValue
    this.addSection(section,path)
  }

  async addSection(section, path){
    const response = await get(path);
    if (response.ok) {
      const body = await response.html;
      const templateElement = document.createElement("template");
      templateElement.innerHTML = body;

      section.appendChild(templateElement.content.firstElementChild);
    }
  }
}

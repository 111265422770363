import { Controller } from "@hotwired/stimulus"
import { get } from "@rails/request.js";
import moment from "moment";

// Connects to data-controller="mail-template"
export default class extends Controller {
  static targets = ['checkbox', 'input', 'example', 'toDisable', 'invitedAt', 'deadline', 'invitedTime', 'reminderInput', 'reminderExample', 'reminderAt'];
  static values = {
    'candidate': String,
    'titles': String,
    'deadline': String,
  }

  inputTargetConnected() {
    this.show_template()
    this.toggleMailTemplateIdField()
  }

  reminderInputTargetConnected() {
    this.show_reminder_template()
    this.toggleReminderMailTemplateIdField()
  }

  updateDeadlineMinDate() {
    if (this.invitedAtTarget.value && this.reminderAtTarget.value) {
      this.reminderAtTarget.min = this.invitedAtTarget.value;
      this.deadlineTarget.min = this.reminderAtTarget.value;
    } else if (this.invitedAtTarget.value) {
      this.deadlineTarget.min = this.invitedAtTarget.value;
      this.reminderAtTarget.min = this.invitedAtTarget.value;
    } else if (this.reminderAtTarget.value) {
      this.deadlineTarget.min = this.reminderAtTarget.value;
      this.reminderAtTarget.min = new Date().toISOString().split('T')[0];
    } else {
      this.deadlineTarget.min = new Date().toISOString().split('T')[0];
      this.reminderAtTarget.min = new Date().toISOString().split('T')[0];
    }
  }

  show_template() {
    let mail_template_id = this.inputTarget.value;
    let mailable = (!this.hasInvitedAtTarget || this.invitedAtTarget.value !== "") && mail_template_id !== ''

    if (mailable) {
      const encodedTitles = encodeURIComponent(this.titlesValue);
      const encodedDeadline = encodeURIComponent(this.deadlineValue);
      get(`/assessments/mail_templates/${mail_template_id}?candidate_id=${this.candidateValue}&titles=${encodedTitles}&deadline=${encodedDeadline}`, {
        responseKind: "turbo-stream"
      })
    } else {
      this.exampleTarget.innerHTML = ""
    }
  }

  show_reminder_template() {
    let reminder_mail_template_id = this.reminderInputTarget.value;
    if (this.reminderAtTarget.value !== "" && reminder_mail_template_id !== "") {
      const encodedTitles = encodeURIComponent(this.titlesValue);
      const encodedDeadline = encodeURIComponent(this.deadlineValue);
      get(`/assessments/mail_templates/${reminder_mail_template_id}?candidate_id=${this.candidateValue}&titles=${encodedTitles}&deadline=${encodedDeadline}&reminder=true`, {
        responseKind: "turbo-stream"
      })
    } else {
      this.reminderExampleTarget.innerHTML = ""
    }
  }

  toggle_invite_template() {
    if (this.checkboxTarget.checked) {
      this.clearInvitedAtDateAndDisable();
    } else {
      this.enableInvitedAt();
    }

    this.show_template();
  }

  clearInvitedAtDateAndDisable() {
    if (this.hasInvitedAtTarget) {
      let date = moment().format('yyyy-MM-DD');
      this.invitedAtTarget.value = date;
      this.invitedAtTarget.disabled = true;
      this.updateInvitedAtHiddenFields("");
      this.invitedTimeTarget.classList.toggle('hidden')
    }
  }

  enableInvitedAt() {
    if (this.hasInvitedAtTarget) {
      this.invitedAtTarget.value = '';
      this.invitedAtTarget.disabled = false;
      this.invitedTimeTarget.classList.toggle('hidden')
    }
  }

  updateInvitedAtHiddenFields(value) {
    document.querySelectorAll('.assignment-invited-at').forEach((hiddenField) => {
      hiddenField.value = value;
    });
  }

  updateDeadlineHiddenFields() {
    const value = this.deadlineTarget.value
    document.querySelectorAll('.assignment-end-date').forEach((hiddenField) => {
      hiddenField.value = value;
    });
    this.deadlineValue = event.target.value;
  }

  toggleMailTemplateIdField() {
    const invitedAtHasValue = this.hasInvitedAtTarget && this.invitedAtTarget.value !== "";
    const checkboxIsChecked = this.hasCheckboxTarget && this.checkboxTarget.checked;
    const onlyHasInputTarget = !this.hasInvitedAtTarget && !this.hasCheckboxTarget;
    const enable = invitedAtHasValue || checkboxIsChecked || onlyHasInputTarget;

    this.inputTarget.disabled = !enable;

    if (enable) {
      this.inputTarget.classList.add('required');
    } else {
      this.inputTarget.classList.remove('required');
    }

    const event = new CustomEvent('formStateChanged', { bubbles: true });
    this.element.dispatchEvent(event);
  }

  toggleReminderMailTemplateIdField() {
    const reminderAtHasValue = this.hasReminderAtTarget && this.reminderAtTarget.value !== "";
    const enable = reminderAtHasValue

    this.reminderInputTarget.disabled = !enable;

    if (enable) {
      this.reminderInputTarget.classList.add('required');
    } else {
      this.reminderInputTarget.classList.remove('required');
    }

    const event = new CustomEvent('formStateChanged', { bubbles: true });
    this.element.dispatchEvent(event);
  }
}

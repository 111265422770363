import {Controller} from "@hotwired/stimulus"
import {post} from "@rails/request.js"
import {useDebounce} from "stimulus-use"

export default class extends Controller {
  static targets = ['submit']
  static values = {path: String}
  static debounces = ['storeData']

  connect() {
    useDebounce(this)
  }

  // we have a form, that normally would submit to the create path,
  // but the alternative button would submit to the storeData path,
  // which does not validate the form, and stores the data in redis.
  // it should be a turbo stream response, so we can update the view
  // without re-rendering the page.
  storeData(event) {
    post(this.submitTarget.formAction, {
      responseKind: "turbo-stream",
      body: new FormData(this.element)
    })
  }
}

import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="filter"
export default class extends Controller {
  static targets = ['count', 'checkbox', 'input']

  connect() {
    if (this.checkboxTargets.length > 0) {
      this.updateOptionsCount()
    }
    if (this.inputTargets.length > 0) {
      this.updateInputCount()
    }
  }

  updateOptionsCount() {
    const count = this.checkboxTargets.filter(checkbox => checkbox.checked).length;
    this.countTarget.textContent = count > 0 ? ` (${count})` : '';
  }

  updateInputCount() {
    const count = this.inputTargets.filter(input => input.value !== '').length;
    this.countTarget.textContent = count > 0 ? ` (${count})` : '';
  }
}

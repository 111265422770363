import {Controller} from "@hotwired/stimulus"

// Connects to data-controller="news"
export default class extends Controller {
  static targets = ['post']
  static values = {
    id: String
  }

  connect() {
    const first_post = document.getElementById(this.idValue)
    this.applyProperties(first_post)
  }

  select() {
    this.unselectPrevious()
    this.applyProperties(this.postTarget)
  }

  applyProperties(element) {
    element?.classList.replace('hover:bg-gray-100', 'bg-gray-100')
    element?.classList.add('font-bold')
  }

  unselectPrevious() {
    const post = document.getElementsByClassName('bg-gray-100 font-bold')[0]
    post?.classList.replace('bg-gray-100', 'hover:bg-gray-100')
    post?.classList.remove('font-bold')
  }
}

import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="clipboard"
export default class extends Controller {
  static values = {
    'text': { type: String, default: '' },
    'preventDefault': { type: Boolean, default: false }
  }

  copy() {
    navigator.clipboard.writeText(this.textValue);
    if (this.preventDefaultValue === true) {
      event.preventDefault();
    }
  }
}

import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="removals"
export default class extends Controller {
  static targets = ['removable']

  remove() {
    this.removableTarget.remove()
  }
}

import { Controller } from "@hotwired/stimulus"
import { post } from "@rails/request.js";

// Connects to data-controller="turbo-frame-missing-handler"
export default class extends Controller {
  static values = { ignoreHandler: Boolean }

  connect () {
    this.boundHandler = this.handleMissingFrame.bind(this)
    document.addEventListener('turbo:frame-missing', this.boundHandler)
  }

  disconnect() {
    document.removeEventListener('turbo:frame-missing', this.boundHandler)
  }

  handleMissingFrame(event) {
    if (this.ignoreHandlerValue) return;

    this.notifyAppsignal(event)

    // Visit the response
    event.preventDefault()
    event.detail.visit(event.detail.response)
  }

  notifyAppsignal(event) {
    post(`/errors/report_frame_missing?url=${event.detail.response.url}&turbo_frame=${event.target.id}`, {
      responseKind: "turbo-stream",
    })
  }
}

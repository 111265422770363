import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="tooltip"
export default class extends Controller {
  connect(){
    let options = { placement: 'right', triggerType: 'hover' }
    let tooltips = document.body.querySelectorAll('[data-tooltip-target-value]');

    for (let i in tooltips) if (tooltips.hasOwnProperty(i)) {
      let triggerElement = tooltips[i];
      let targetElement = document.body.querySelector('[data-tooltip-id="' + tooltips[i].dataset.tooltipTargetValue + '"]');
      new Tooltip(targetElement, triggerElement, options);
    }
  }
}

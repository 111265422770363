import { Controller } from "@hotwired/stimulus"
import { useHotkeys } from "stimulus-use"

// Connects to data-controller="drawer"
export default class extends Controller {
  static targets = ['drawer']
  static outlets = ['media']

  connect() {
    useHotkeys(this, {
      'esc': [this.closeDrawer]
    })
  }

  showDrawer() {
    this.drawerTarget.classList.remove('hidden')
  }

  hideDrawer() {
    this.drawerTarget.classList.add('hidden')
  }

  closeDrawer() {
    this.mediaOutlet.unselectPrevItem()
    this.hideDrawer()
  }
}

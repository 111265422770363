import {Controller} from "@hotwired/stimulus";
import {post} from "@rails/request.js";

export default class extends Controller {
  static values = { storePath: String }

  addAssignment = (event) => {
    const assignmentId = event.currentTarget.dataset.assignmentId;
    const assignmentType = event.currentTarget.dataset.assignmentType;

    this.storeData(assignmentType, assignmentId)
  }

  removeAssignment(event) {
    const hiddenId = event.currentTarget.dataset.hiddenId;
    const assignmentId = hiddenId.replace(/^\D+/g, '');  // Extract the numeric part
    const match = hiddenId.match(/bulk_assignments_(.*)_([0-9]+)$/);
    const assignmentType = match ? match[1] : null;

    this.storeData(assignmentType + '_ids', assignmentId, 'delete');
  }


  storeData(type, id, action='store') {
    const formData = new FormData();
    formData.append(type, id);

    if (action === 'delete') {
      formData.append('delete', '1');
    }

    const path = this.storePathValue

    post(path, { body: formData, responseKind: "turbo-stream" })
      .catch(error => {
        console.log('Error:', error);
      });
  }
}

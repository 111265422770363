import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="radio-buttons-select"
export default class extends Controller {
  static targets = [ 'selectField', 'listReminderMailTemplate', 'listMailTemplate' ]
  static values = { attribute: String }

  connect() {
    this.selectFieldTarget.dispatchEvent(new Event("change"));
  }

  updateSelectField(event) {
    this.selectFieldTarget.value = event.target.value;
    this.selectFieldTarget.dispatchEvent(new Event("change"));
  }

  moveCheckedToTop() {
    if(this.hasListReminderMailTemplateTarget) {
      const radioButtonElement = document.getElementById(`reminder_mail_template_element_${event.target.value}`)
      this.listReminderMailTemplateTarget.prepend(radioButtonElement)
      this.listReminderMailTemplateTarget.scrollTo({top: 0, behavior: 'smooth'});
    }else{
      const radioButtonElement = document.getElementById(`mail_template_element_${event.target.value}`)
      this.listMailTemplateTarget.prepend(radioButtonElement)
      this.listMailTemplateTarget.scrollTo({top: 0, behavior: 'smooth'});
    }
  }
}

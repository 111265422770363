import {Controller} from "@hotwired/stimulus"

// Connects to data-controller="selected-row"
export default class extends Controller {
  static targets = ['checkbox']
  static values = {
    extraClassNames: {type: Array, default: ['font-bold', 'bg-gray-selected-row']}
  }

  connect() {
   this.toggle()
  }

  toggle() {
    if (this.checkboxTarget.checked) {
      this.element.classList.add(...this.extraClassNamesValue)
    } else {
      this.element.classList.remove(...this.extraClassNamesValue)
    }
  }
}

import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="local-search"
export default class extends Controller {
  static targets = ['query', 'searchable', 'noResults', 'language']

  search() {
    let lowerCaseSearchTerm = this.queryTarget.value.toLowerCase()
    let foundResults = 0

    this.searchableTargets.forEach((el) => {
      let searchableKey =  el.getAttribute("data-search-key")
      let match = searchableKey.includes(lowerCaseSearchTerm) && this.matchLanguage(el)

      if (match) {
        foundResults += 1;
      }
      el.classList.toggle("hidden", !match)
    })

    this.noResultsTarget.classList.toggle("hidden", !(foundResults == 0))
  }

  matchLanguage(element) {
    if (!this.hasLanguageTarget) return true

    let searchableLanguage =  element.getAttribute("data-language")
    let languageTerm = this.languageTarget.value

    return searchableLanguage.includes(languageTerm)
  }

  prevent(event) {
    event.preventDefault();
  }
}

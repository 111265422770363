import "flowbite/dist/flowbite.turbo"
import * as Turbo from "@hotwired/turbo"
import "@hotwired/turbo-rails"

// stimulus controllers
import "./controllers"

import * as ActiveStorage from "@rails/activestorage"
ActiveStorage.start()

import "trix"
import "@rails/actiontext"

import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="link"
export default class extends Controller {
  static targets = ['link']

  disable() {
    this.linkTarget.classList.add('pointer-events-none');
    setTimeout(() => {
      this.linkTarget.classList.remove('pointer-events-none');
    }, 3000);
  }
}

import {Controller} from "@hotwired/stimulus"

// Connects to data-controller="form-preview-refresher"
export default class extends Controller {
  static targets = ['button']

  connect() {
    this.buttonTarget.classList.add('hidden')
  }

  refresh() {
    this.element.requestSubmit(this.buttonTarget)
  }
}

import {Controller} from "@hotwired/stimulus"

// Connects to data-controller="highlight"
export default class extends Controller {
  static targets = ["competency"]
  static classes = ["hover", "click", "backgroundReset"]

  hoverHandler(event) {
    const competency = event.currentTarget.dataset.competencyName
    if (this.isHighlighted(event.currentTarget)) return;

    if (event.type === 'mouseover') {
      this.handleEvent(competency, true, 'hover')
    } else if (event.type === 'mouseout') {
      this.handleEvent(competency, false, 'hover')
    }
  }

  toggleHighlight(event) {
    const competencyName = event.currentTarget.dataset.competencyName
    const isHighlighted = this.toggleHighlightedState(event.currentTarget, competencyName);
    this.handleEvent(competencyName, isHighlighted, 'click');
  }

  handleEvent(competencyName, applyStyles, type) {
    this.competencyTargets.forEach(element => {
      if (element.dataset.competencyName !== competencyName) return;

      const isHover = type === 'hover';
      this.toggleClasses(element, this.hoverClasses, isHover ? applyStyles : false);
      this.toggleClasses(element, this.clickClasses, applyStyles);

      Array.from(element.children).forEach(child => {
        this.toggleClasses(child, this.hoverClasses, isHover ? applyStyles : false);
        this.toggleClasses(child, this.clickClasses, applyStyles);

        if (child.tagName === 'DT') {
          this.toggleClasses(child, this.backgroundResetClasses, !applyStyles);
        }
      });
    });
  }

  toggleClasses(element, classes, add) {
    classes.forEach(cls => element.classList.toggle(cls, add));
  }

  toggleHighlightedState(element, competencyName) {
    const isCurrentlyHighlighted = this.isHighlighted(element);
    this.competencyTargets.forEach(element => {
      if (element.dataset.competencyName !== competencyName) return;
      element.dataset.highlighted = isCurrentlyHighlighted ? 'false' : 'true';
    });
    return !isCurrentlyHighlighted;
  }

  isHighlighted(element) {
    return element.dataset.highlighted === 'true';
  }
}

import {Controller} from "@hotwired/stimulus"

// Connects to data-controller="upload"
export default class extends Controller {
  static targets = ['file', 'form']
  static values = {
    maxsize: {type: Number, default: 50000000}
  }

  file() {
    this.fileTarget.click()
  }

  submit() {
    if (this.fileTarget.files[0].size <= this.maxsizeValue) {
      this.formTarget.requestSubmit()
    } else {
      alert('Upload failed')
    }
  }
}

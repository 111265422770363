import {
  Controller
} from "@hotwired/stimulus"
import { Turbo } from "@hotwired/turbo-rails"

// Connects to data-controller="media"
export default class extends Controller {
  static targets = ['item']
  static outlets = ['drawer']
  static values = {
    uuid: String,
    url: String
  }

  drawerOutletConnected() {
    if (!this.uuidValue) return

    this.showItemByUuid()
  }

  selectItem() {
    if (this.itemTarget.classList.contains('font-bold')) {
      this.drawerOutlet.hideDrawer()
      this.itemTarget.classList.replace('font-bold', 'font-normal')
    } else {
      this.drawerOutlet.showDrawer()
      this.unselectPrevItem()
      this.itemTarget.classList.replace('font-normal', 'font-bold')
    }
    // Capybara with headless chrome also triggers this on a delete request, which has a longer nested path, so only continue to the page if it's a main route with id of the item
    if (this.urlValue && this.urlValue !== (window.location.pathname + window.location.search) && this.urlValue.split('/').length == 2) {
      Turbo.visit(this.urlValue)
    }
  }

  showItemByUuid() {
    if (this.itemTarget.getAttribute('uuid') === this.uuidValue) {
     this.selectItem()
    }
  }

  unselectPrevItem() {
    const media_item = document.getElementsByClassName('font-bold border-b')[0]
    media_item?.classList.replace('font-bold', 'font-normal')
  }
}

import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["credits", "euroAmount"];
  static values = { creditPrice: Number };

  updateEuroAmount() {
    const credits = parseFloat(this.creditsTarget.value);
    const euroAmount = credits * this.creditPriceValue;
    document.getElementById('amount_in_euro').textContent = isNaN(euroAmount) ? '' : `${euroAmount.toFixed(2)}€`;
  }
}

import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="list-order"
export default class extends Controller {
  connect() {
    this.checkPosition(this.element)
  }

  moveUp() {
    const element = this.element
    const previousElement = element.previousElementSibling

    if (previousElement) {
      element.parentNode.insertBefore(element, previousElement)
      this.checkPosition(element)
      this.checkPosition(previousElement)
    }
  }

  moveDown() {
    const element = this.element
    const nextElement = element.nextElementSibling

    if (nextElement) {
      element.parentNode.insertBefore(nextElement, element)
      this.checkPosition(element)
      this.checkPosition(nextElement)
    }
  }
  
  checkPosition(element) {
    const upButton = element.querySelector('[data-direction="up"]');
    const downButton = element.querySelector('[data-direction="down"]');

    this.toggleButtonState(upButton, this.isFirstElement(element));
    this.toggleButtonState(downButton, this.isLastElement(element));
  }

  toggleButtonState(button, isDisabled) {
    button.disabled = isDisabled;
    const svg = button.querySelector("svg");
    svg.classList[isDisabled ? 'add' : 'remove']("fill-gray-nav");
  }

  isFirstElement(element) {
    return !element.previousElementSibling;
  }

  isLastElement(element) {
    return !element.nextElementSibling;
  }
}

import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="toggle-visibility"
export default class extends Controller {
  static values = { target: String }

  toggle(event) {
    event.preventDefault()
    let elements = document.body.querySelectorAll(this.targetValue);
    elements.forEach(element => element.classList.toggle('hidden'));
  }
}

import {Controller} from "@hotwired/stimulus"

// Connects to data-controller="select-filter"
export default class extends Controller {
  static targets = ["query", "form", "list", 'language', 'assessment', 'category'];

  connect() {
    this.toggleCategoryFilter();
  }

  storeSelectedValues() {
    let srcValue = ''

    // Not generic yet, if we want to apply these type of filter for more forms we should base this on stimulus targets or stimulus values
    const form = this.formTarget;
    const programs = form.querySelectorAll('input[name="bulk_assignments[program_ids][]"]');
    const assessmentDefinitions = form.querySelectorAll('input[name="bulk_assignments[assessment_definition_ids][]"]');
    const assessmentPrograms = form.querySelectorAll('input[name="bulk_assignments[assessment_program_ids][]"]');

    programs.forEach((program) => {
      srcValue += `&bulk_assignments[program_ids][]=${program.value}`
    })

    assessmentDefinitions.forEach((assessmentDefinition) => {
      srcValue += `&bulk_assignments[assessment_definition_ids][]=${assessmentDefinition.value}`
    })

    assessmentPrograms.forEach((assessmentProgram) => {
      srcValue += `&bulk_assignments[assessment_program_ids][]=${assessmentProgram.value}`
    })
    return srcValue
  }

  apply_filter() {
    const form = this.formTarget;
    const frame = this.listTarget;
    const searchQuery = this.queryTarget;

    let endpoint = new URL(window.location.href);
    endpoint.searchParams.set("query", searchQuery.value);
    endpoint.searchParams.set("step", "enrollment");

    if (this.hasAssessmentTarget) {
      const assessment = this.assessmentTarget;
      const assessmentType = assessment.value;
      if (assessmentType && assessmentType === '?step=enrollment&filter=assessment_definitions') {
        endpoint.searchParams.set("filter", "assessment_definitions");
      } else if (assessmentType && assessmentType === '?step=enrollment&filter=assessment_programs') {
        endpoint.searchParams.set("filter", "assessment_programs");
      } else {
        endpoint.searchParams.set("filter", "programs");
      }
    }

    this.toggleCategoryFilter()

    if (this.hasLanguageTarget) {
      const language = this.languageTarget;
      if (language && language.options[language.selectedIndex].value !== '') {
        endpoint.searchParams.set("language", language.options[language.selectedIndex].value);
      }
    }

    if (this.hasCategoryTarget) {
      const category = this.categoryTarget;
      const categoryType = category.value;
      endpoint.searchParams.set("category", categoryType);
    }

    if (form.dataset['kredisKeyKeyValue']) {
      endpoint.searchParams.set("kredis_key", form.dataset['kredisKeyKeyValue']);
    }

    frame.src = endpoint.toString();
  }

  toggleCategoryFilter() {
    const categoryFilter = this.categoryTarget
    const selectedAssessment = this.assessmentTarget.value;

    if (selectedAssessment.includes("assessment")) {
      categoryFilter.classList.remove("hidden");
    } else {
      categoryFilter.classList.add("hidden")
    }
  }
}

import {Controller} from "@hotwired/stimulus"
import {useDebounce, useHotkeys} from "stimulus-use"

// Connects to data-controller="search"
export default class extends Controller {
  static targets = ['query', 'form']
  static outlets = ['sidebar']
  static debounces = ['process']

  connect() {
    useDebounce(this, { wait: 500 })
    useHotkeys(this, {
      '/': [this.focusSearch]
    })
  }

  process() {
    this.formTarget.requestSubmit()
  }

  toggleSidebar() {
    this.sidebarOutlet.toggle()
  }

  focusSearch(event) {
    event.preventDefault()
    this.queryTarget.focus()
    this.queryTarget.select()
  }
}

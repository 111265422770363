import { Controller } from "@hotwired/stimulus"
import { useDebounce } from "stimulus-use"
import { get } from "@rails/request.js"

// Connects to data-controller="media-item-shares"
export default class extends Controller {
  static targets = ["userTypeSelect", "form", "memberType", "searchInput"]
  static debounces = ['select_shared_with_debounced']

  connect() {
    useDebounce(this, { wait: 500 })
  }

  select_shared_with_debounced() {
    this.select_shared_with()
  }

  select_shared_with() {
    let search = this.searchInputTarget.value
    let shared_with_type = this.memberTypeTarget.querySelector('input[type="radio"]:checked').value
    let target = this.userTypeSelectTarget.id
    let media_item_id = this.formTarget.dataset.id
    get(`/media_items/${media_item_id}/media_item_shares/shareable_with?target=${target}&shared_with_type=${shared_with_type}&search=${search}`, {
      responseKind: "turbo-stream"
    })
  }
}

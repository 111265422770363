import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="password-toggle"
export default class extends Controller {
  static targets = ['show', 'hide', 'input', 'minlength']
  static values = {
    minlength: Number
  }

  connect() {}

  toggle() {
    if (this.isHidden() === true) {
      this.showTarget.classList.add('hidden')
      this.hideTarget.classList.remove('hidden')
      this.inputTarget.type = 'text'
    } else {
      this.showTarget.classList.remove('hidden')
      this.hideTarget.classList.add('hidden')
      this.inputTarget.type = 'password'
    }
  }

  validate() {
    if (this.hasMinlengthValue === false) { return }

    if (this.inputTarget.value.length >= this.minlengthValue) {
      this.minlengthTarget.classList.add('correct')
    } else {
      this.minlengthTarget.classList.remove('correct')
    }
  }

  // true if the button to show the password is visible
  isHidden() {
    // if the show target does not have the hidden class, then it is visible
    return !this.showTarget.classList.contains('hidden')
  }
}

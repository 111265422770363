import {Controller} from "@hotwired/stimulus"

// Connects to data-controller="otp"
export default class extends Controller {
  static targets = ['input', 'code']

  connect () {
    // The keydown event is a global event, so stimulus can't
    // help us here. Instead we're using a custom event listener.
    // https://www.betterstimulus.com/events.html
    this.boundHandleBackspace = this.handleBackspace.bind(this)
    this.inputTargets.forEach(element => {
      element.addEventListener('keydown', this.boundHandleBackspace);
    });
  }

  disconnect() {
    this.inputTargets.forEach(element => {
      element.removeEventListener('keydown', this.boundHandleBackspace);
    });
  }

  process(event) {
    const input = event.target

    input.value = input.value.replace(/[^0-9]/g, '')

    // if it's the first input, and a whole code is copied, spread it across the inputs
    if (input.dataset.index === '0' && input.value.length === 6) {
      const value = input.value
      this.inputTargets.forEach((input, index) => {
        input.value = value.slice(index, index + 1)
        input.focus()
      })
    } else if (input.checkValidity() === true && input.dataset.index !== '5') {
      // Otherwise, move to the next input
      input.nextElementSibling.focus()
    }

    // submit the form if it's valid
    if (input.form.checkValidity() === true) {
      // The gem requires the submitted form to have a single 'code' value, so we are concatenating the values
      // of our inputs into that single field.
      this.codeTarget.value = this.inputTargets.map(input => input.value).join('')

      // submit the form
      input.form.requestSubmit()
    }
  }

  handleBackspace(event) {
    const input = event.target

    if (event.key == "Backspace" && input.dataset.index !== '0') {
      input.previousElementSibling.focus()
    }
  }
}
